:root {
  --red-era: #CF092C;
  --red-dark-era: #8F1136;
  --blue-era: #142C60;
}

/* styles era */
.content-era-layout .text-primary-dark {
  color: var(--red-dark-era)!important;
}

.content-era-layout .accordion-button:not(.collapsed) {
  background: var(--blue-era);
  color: white!important;
}

.content-era-layout .box-shadow-none {
  box-shadow: none!important;
}

.content-era-layout, .content-era-layout .text-dark-era, .content-era-layout .accordion-button, .content-era-layout h1, .content-era-layout h2, .content-era-layout h3, .content-era-layout h4, .content-era-layout h5 {
  color: var(--blue-era)!important;
}

.content-era-layout .bg-primary-dark {
  background: var(--blue-era)!important;
}

.content-era-layout .card-buy-item.active, .content-era-layout .card-buy-item.active span, .content-era-layout .btn-dark, .content-era-layout .btn-dark:hover {
  background: var(--blue-era)!important;
  background-color: var(--blue-era)!important;
  color: white!important;
}

.content-era-layout .btn-dark span, .content-era-layout .btn-dark i, .content-era-layout .btn-danger span, .content-era-layout .btn-danger, .content-era-layout .btn-danger i {
  color: white!important;
}

.content-era-layout .card-buy-item.active hr {
  background: white!important;
}
.content-era-layout .stepper.stepper-links .stepper-nav .stepper-item.current .stepper-title {
  color: var(--blue-era)!important;
}

.content-era-layout .stepper.stepper-links .stepper-nav .stepper-item.current:after {
  background: var(--blue-era)!important;
}

.padding-left-era .col-md-3 div:nth-child(2) {
  padding-left: 8px;
}