.demo .wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.demo .before,
.demo .after {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
}

.demo .content-image {
  width: 100vw;
  height: 100vh;
}

.demo .after {
  width: 125px;
}

.demo .scroller {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.9;
  pointer-events: auto;
  cursor: pointer;
}

.demo .scroller:hover {
  opacity: 1;
}

.demo .scroller .iconMove {
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  line-height: 40px;
  font-size: 37px;
}

.demo .scrolling {
  pointer-events: none;
  opacity: 1;
  z-index: 1;
}

.demo .scroller__thumb {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.demo .scroller:before,
.demo .scroller:after {
  content: ' ';
  display: block;
  width: 5px;
  height: 9999px;
  position: absolute;
  left: 50%;
  margin-left: -2.5px;
  z-index: 30;
  transition: 0.1s;
}
.demo .scroller:before {
  top: 100%;
}
.demo .scroller:after {
  bottom: 100%;
}

.demo .scroller {
  border: 5px solid #fff;
}
.demo .scroller:before,
.demo .scroller:after {
  background: #fff;
}

.demo .logo-demo {
  width: 180px;
}

.demo .info-menu {
  width: 330px;
}

.demo .info-menu ul {
  list-style: square;
}

.demo .info-menu ul li {
  padding: 3px;
  cursor: pointer;
}

.info-star {
  font-size: 1.6em!important;
  position: absolute;
  top: -1px !important;
  right: -6px;
}

.info-star::before {
  background: #fff;
  padding: 4px;
  border: 2px solid;
  border-radius: 30px;
}

.card-buy-item {
  min-width: 280px;
  border: 1px solid;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}

.min-width-100 {
  min-width: 100px;
}

.card-buy-item.active {
  background: var(--bs-primary);
  color: #fff;
}

.card-buy-item:hover {
  box-shadow: 0px 1px 0px;
}

.label-topbar-info {
  margin: 0px;
  padding: 0px 15px;
  border-right: 1px solid;
}

.heightImgMin {
  min-width: 130px;
  max-width: 130px;
  min-height: 80px;
  max-height: 80px;
}

.heightMin {
  width: auto;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  margin: auto;
}

.optional {
  margin: 0px 8px;
  font-size: 10px;
}